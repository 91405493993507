import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/service/auth/auth.service';
import Swal from 'sweetalert2';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public loginForm: FormGroup;
  public registerForm: FormGroup;
  specialities: string[];
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Tunisia, CountryISO.France];

  owlcarousel = [
    {
      title: "Bienvenue chez Xtend-Medical",
      desc: "Une simple inscription vous permettra d’avoir votre espace cloud privé et sécurisé dédié à votre cabinet.",
    },
    {
      title: "Bienvenue chez Xtend-Medical",
      desc: "Vous bénéficierez de 5 jours d’utilisation gratuite, après lequel vous devrez renouveler votre abonnement",
    },
    {
      title: "Bienvenue chez Xtend-Medical",
      desc: "Le premier ERP-Medical dédie au médecin pour digitaliser ces travails",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true,
    autoplay: true
  };
  submitted = false;

  constructor(private formBuilder: FormBuilder, private auth: AuthService, private route: ActivatedRoute) {
    this.createRegisterForm();
    this.auth.logout();
  }

  changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}

  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      speciality: [null, [Validators.required]],
      password: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      confirmPassword: [''],
    })
  }


  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if(params && params.type) {
        localStorage.setItem('subscription', params.type)
      }
    });
    this.auth.getSpecialites().subscribe(e => this.specialities = e);
  }

  signup() {
    this.submitted = true;
    if (this.registerForm.valid){
      const phone = this.registerForm.value.phone.internationalNumber //e164Number.replace(/\s/g, '').substring(1);
      this.registerForm.controls.phone.setValue(phone);
      this.auth.registerUser(this.registerForm.value);
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Formulaire manquant',
        text: 'Verifiez le formulaire'
      });
    }
  }

}