import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import {UtilisateurModel} from '../model/utilisateur.model';
import {Router} from '@angular/router';
import {AppointmentModel, PatientModel, SettingModal} from '../model/models.model';
import {CalendarEvent} from 'calendar-utils';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private apiUrl = environment.API + '/rdvtypes/';

  constructor(private http: HttpClient, private router: Router) {}

  createRdvType(rdvType): Observable<SettingModal>{
    return this.http.post<SettingModal>(this.apiUrl, rdvType);
  }
  getRdvType(): Observable<SettingModal[]> {
    return this.http.get<SettingModal[]>(this.apiUrl);
  }

  updateRdvType(rdvType): Observable<SettingModal>{
    return this.http.put<SettingModal>(this.apiUrl, rdvType);
  }
}
