import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {TenantModel, UserModel} from '../model/models.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.API + '/user';

  constructor(private http: HttpClient, private router: Router) {}

  addUser(patient: UserModel): Observable<UserModel>{
    return this.http.post<UserModel>(this.apiUrl, patient);
  }
  getUsers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(this.apiUrl);
  }

  deleteUser(id): Observable<UserModel> {
    return this.http.delete<UserModel>(`${this.apiUrl}/${id}`);
  }

  updateUser(user): Observable<UserModel> {
    return this.http.put<UserModel>(`${this.apiUrl}`, user);
  }

  updateUserPassword(user): Observable<UserModel> {
    return this.http.put<UserModel>(`${this.apiUrl}/update-password`, user);
  }

  getProfileDetails(): Observable<UserModel> {
    return this.http.get<UserModel>(`${this.apiUrl}/user-details`);
  }

  getTenantDetails(): Observable<TenantModel> {
    return this.http.get<TenantModel>(`${this.apiUrl}/tenant`);
  }

  updateTenant(tenant): Observable<TenantModel> {
    return this.http.put<TenantModel>(`${this.apiUrl}/tenant`, tenant);
  }

}
