<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-3">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/profile-pic.png" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{user?.firstname}} {{user?.lastname}}</h5>
                        <span>{{user?.email}}</span>
                        <!-- <div class="social">
                            <div class="form-group btn-showcase">
                                <button class="btn social-btn btn-fb d-inline-block">
                                    <i class="fa fa-facebook"></i></button>
                                <button class="btn social-btn btn-twitter d-inline-block"><i
                                        class="fa fa-google"></i></button>
                                <button class="btn social-btn btn-google d-inline-block mr-0"><i
                                        class="fa fa-twitter"></i></button>
                            </div>
                        </div> -->
                    </div>
                    <hr>
                    <div class="project-status">
                        <h5 class="f-w-600">Expiration dans</h5>
                        <div class="media">
                            <div class="media-body">
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <div id="countdown">
                                            <ul class="count-down list-unstyled">
                                                <li id="days" class="count-number list-inline-item m-2">{{days}}<p
                                                        class="count-head">Jours</p>
                                                </li>
                                                <li id="hours" class="count-number list-inline-item m-2">{{hours}}<p
                                                        class="count-head">Heurs</p>
                                                </li>
                                                <li id="mins" class="count-number list-inline-item m-2">{{minutes}}<p
                                                        class="count-head">Minutes</p>
                                                </li>
                                                <li id="secs" class="count-number list-inline-item m-2">{{seconds}}<p
                                                        class="count-head">Secondes</p>
                                                </li>
                                                <li id="end" class="h1"></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-9">
            <div class="card tab2-card">
                <div class="card-body">
                    <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i class="fa fa-user mx-2"></i>Profile
                            </ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <h5 class="f-w-600"> Profile</h5>
                                    <div class="table-responsive profile-table">
                                        <table class="table table-responsive">
                                            <tbody>
                                                <tr>
                                                    <td>Prénom:</td>
                                                    <td>{{user?.firstname}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Nom:</td>
                                                    <td>{{user?.lastname}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email:</td>
                                                    <td>{{user?.email}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Dérnier visite:</td>
                                                    <td>{{user?.lastLogin | date: 'medium'}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date de création:</td>
                                                    <td>{{user?.joinedUs | date}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr>
                                    <h1>Changer le mot de passe</h1>
                                    <form [formGroup]="passwordForm" (ngSubmit)="updatePassword()">

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="validationCustom01"
                                                        class="col-form-label pt-0"><span>*</span> Actuel</label>
                                                    <input formControlName="password" placeholder="Mot de passe Actuel" class="form-control"
                                                        id="validationCustom01" type="password"
                                                        [ngClass]="{ 'is-invalid': submitted && passwordForm.controls.password.errors }">

                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="validationCustom01"
                                                        class="col-form-label pt-0"><span>*</span> Nouveau</label>
                                                    <input formControlName="newPassword" placeholder="Nouveau mot de passe" class="form-control"
                                                        id="validationCustom01" type="password"
                                                        [ngClass]="{ 'is-invalid': submitted && passwordForm.controls.newPassword.errors }">

                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="validationCustom01"
                                                        class="col-form-label pt-0"><span>*</span> Confirmer</label>
                                                    <input formControlName="confirmaion" placeholder="Confirmer la mot de passe" class="form-control"
                                                        id="validationCustom01" type="password"
                                                        [ngClass]="{ 'is-invalid': submitted && passwordForm.controls.confirmaion.errors || passwordForm.value.newPassword !== passwordForm.value.confirmaion }">
                                                        <div
                                                        *ngIf="((submitted && passwordForm.controls.confirmaion.errors) || (passwordForm.value.newPassword !== passwordForm.value.confirmaion))"
                                                        class="invalid-feedback">
                                                        Verifiez
                                                      </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center">
                                                <button type="submit" class="btn btn-primary" >Enregistrer les modifications</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i class="fa fa-hospital mx-2"></i>Entête
                            </ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <a class="btn btn-info mx-2" (click)="enableTenantEdit()"
                                        style="cursor: pointer;"><i class="fa fa-edit mx-2"></i>Modifiez
                                    </a>
                                    <button type="button" class="btn btn-secondary"
                                        styleSheetFile="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css"
                                        printSectionId="prescription-print-section" ngxPrint>
                                        <i class="fa fa-eye mx-2"></i>Aperçu
                                    </button>
                                    <div class="table-responsive profile-table mt-4">
                                        <table class="table table-responsive">
                                            <tbody *ngIf="!enableTenantEditVariable">
                                                <tr>
                                                    <td><i class="fa fa-map-marker mx-2"></i>Adress:</td>
                                                    <td>{{tenant?.address}}</td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-envelope mx-2"></i>Email:</td>
                                                    <td>{{tenant?.email}}</td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-phone mx-2"></i>Fax/Fix:</td>
                                                    <td>{{tenant?.fax}}</td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-mobile mx-2"></i>Mobile:</td>
                                                    <td>{{tenant?.mobile}}</td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-university mx-2"></i>CNAM:</td>
                                                    <td>{{tenant?.cnam}}</td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-university mx-2"></i>Entête en français:
                                                    </td>
                                                    <td>{{tenant?.frHead}}</td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-university mx-2"></i>Entête en arabe:
                                                    </td>
                                                    <td>{{tenant?.arHead}}</td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-university mx-2"></i>Spécialité:</td>
                                                    <td>{{tenant?.speciality}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date de création du compte:</td>
                                                    <td>{{tenant?.creationDate | date: 'medium'}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="enableTenantEditVariable">
                                                <tr>
                                                    <td><i class="fa fa-map-marker mx-2"></i>Adress:</td>
                                                    <td>
                                                        <textarea [(ngModel)]="tenant.address" placeholder="Adress"
                                                            class="form-control" type="text"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-envelope mx-2"></i>Email:</td>
                                                    <td>
                                                        <input [(ngModel)]="tenant.email" placeholder="Email"
                                                            class="form-control" type="text">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-phone mx-2"></i>Fax/Fix:</td>
                                                    <td>
                                                        <input [(ngModel)]="tenant.fax" placeholder="Fax/Fix"
                                                            class="form-control" type="text">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-mobile mx-2"></i>Mobile:</td>
                                                    <td>
                                                        <input [(ngModel)]="tenant.mobile" placeholder="Mobile"
                                                            class="form-control" type="text">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-university mx-2"></i>CNAM:</td>
                                                    <td>
                                                        <input [(ngModel)]="tenant.cnam" placeholder="CNAM"
                                                            class="form-control" type="text">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-university mx-2"></i>Entête en français:
                                                    </td>
                                                    <td>
                                                        <textarea [(ngModel)]="tenant.frHead"
                                                            placeholder="Entête en français" class="form-control"
                                                            type="text"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-university mx-2"></i>Entête en arabe:
                                                    </td>
                                                    <td>
                                                        <textarea [(ngModel)]="tenant.arHead"
                                                            placeholder="Entête en arabe" class="form-control"
                                                            type="text"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><i class="fa fa-university mx-2"></i>Spécialité:</td>
                                                    <td>
                                                        <ng-select virtualScroll="true" [(ngModel)]="tenant.speciality"
                                                            [placeholder]="'Indiquer votre Spécialité'"
                                                            [multiple]="false">
                                                            <ng-option *ngFor="let specialitie of specialities"
                                                                [value]="specialitie">{{specialitie}}
                                                            </ng-option>
                                                        </ng-select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Date de création du compte:</td>
                                                    <td>{{tenant?.creationDate | date: 'medium'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <div class="form-group mb-2 mt-4" *ngIf="enableTenantEditVariable">
                                        <div class="product-buttons text-center">
                                            <button type="button" class="btn btn-primary"
                                                (click)="updateTenant()">Enregistrer</button>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i class="fa fa-info mx-2"></i>Types des Rendez-Vous
                            </ng-template>
                            <ng-template ngbTabContent>
                                <div class="container-fluid">
                                    <div class="btn-popup pull-right">
                                        <a class="btn btn-secondary" (click)="addModal(modalContent)">Ajouter</a>
                                    </div>
                                    <div class="table table-responsive w-100">
                                        <table class="w-100" *ngIf="rdvTypes?.length">
                                            <thead>
                                                <th>Type</th>
                                                <th>Prix</th>
                                                <th>Durée</th>
                                                <th>Couleur</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let rdv of rdvTypes">
                                                    <td>{{rdv.type}}</td>
                                                    <td>{{rdv.price}} DT</td>
                                                    <td>{{rdv.duration}} min</td>
                                                    <td><input type="color" [value]="rdv?.color" disabled></td>
                                                    <td>
                                                        <span style="cursor: pointer; margin-right: 10px"
                                                            (click)="editRdvType(rdv, modalContent)">
                                                            <i class="fa fa-edit mx-2"></i>
                                                        </span>
                                                        <span style="cursor: pointer; color: red"
                                                            (click)="deleteRdvType(rdv)">
                                                            <i class="fa fa-trash mx-2"></i>
                                                        </span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #modalContent>
    <div class="modal-header">
        <h5 class="modal-title">Ajouter une nouvelle type de rendez-vous</h5>
        <button type="button" class="close">
            <span aria-hidden="true" (click)="closeModal(); rdvTypeForm.reset()">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="rdvTypeForm" (ngSubmit)="submitRdvType()">

            <div class="form-group">
                <label class="col-form-label pt-0"><span>*</span> Type</label>
                <input formControlName="type" placeholder="Type de Rendez-vous" class="form-control" type="text"
                    [ngClass]="{ 'is-invalid': submitted && rdvTypeForm.controls.type.errors }">
                <div *ngIf="(submitted && rdvTypeForm.controls.type.errors?.required) || (rdvTypeForm.controls.type.touched && rdvTypeForm.controls.type.errors?.required)"
                    class="invalid-feedback">
                    Veuillez remplir ce champ.
                </div>
            </div>
            <div class="form-group">
                <label class="col-form-label pt-0"><span>*</span> Coleur indicatif</label>
                <input formControlName="color" placeholder="Type de Rendez-vous" class="form-control" type="color"
                    [ngClass]="{ 'is-invalid': submitted && rdvTypeForm.controls.color.errors }">
                <div *ngIf="(submitted && rdvTypeForm.controls.color.errors?.required) || (rdvTypeForm.controls.color.touched && rdvTypeForm.controls.color.errors?.required)"
                    class="invalid-feedback">
                    Veuillez remplir ce champ.
                </div>
            </div>
            <div class="form-group">
                <label class="col-form-label pt-0"><span>*</span> Tarif</label>
                <input formControlName="price" placeholder="Tarif en TTC" class="form-control" type="number"
                    [ngClass]="{ 'is-invalid': submitted && rdvTypeForm.controls.price.errors }">
                <div *ngIf="(submitted && rdvTypeForm.controls.price.errors?.required) || (rdvTypeForm.controls.price.touched && rdvTypeForm.controls.price.errors?.required)"
                    class="invalid-feedback">
                    Veuillez remplir ce champ.
                </div>
            </div>
            <div class="form-group">
                <label class="col-form-label pt-0"><span>*</span> Dureé</label>
                <input formControlName="duration" placeholder="indiqueé en minutes EX:(60) = 1H = 60 min"
                    class="form-control" type="number"
                    [ngClass]="{ 'is-invalid': submitted && rdvTypeForm.controls.duration.errors }">
                <div *ngIf="(submitted && rdvTypeForm.controls.duration.errors?.required) || (rdvTypeForm.controls.duration.touched && rdvTypeForm.controls.duration.errors?.required)"
                    class="invalid-feedback">
                    Veuillez remplir ce champ.
                </div>
            </div>

            <div class="form-group mb-0">
                <div class="product-buttons text-center">
                    <button type="button" class="btn btn-danger mr-2"
                        (click)="closeModal(); rdvTypeForm.reset()">Annuler</button>
                    <button *ngIf="!activateEditBtn" type="submit" class="btn btn-primary mr-1">Ajouter</button>
                    <button *ngIf="activateEditBtn" type="submit" class="btn btn-primary mr-1">Modifier</button>
                </div>
            </div>
        </form>
    </div>
    <!--    <div class="modal-footer">-->
    <!--      -->
    <!--        <button type="button" class="btn btn-outline-secondary" (click)="close.dismissAll('')">-->
    <!--            OK-->
    <!--        </button>-->
    <!--    </div>-->
</ng-template>

<div id="prescription-print-section" hidden>
    <div class="container" style="height:148mm;width:210mm;">
        <div class="row" *ngIf="tenant?.arHead">
            <div class="col">
                <h2 class="mb-0">{{tenant?.frHead}}</h2>
                <p>{{tenant?.speciality}}</p>
            </div>
            <div class="col text-center">
                <img width="75px" src="assets/images/profile-pic.png" />

            </div>
            <div class="col text-right">
                <h2 class="mb-0">{{tenant?.arHead}}</h2>
                <p>{{tenant?.speciality}}</p>
            </div>
        </div>
        <div class="row" *ngIf="!tenant?.arHead">
            <div class="col">
                <img width="75px" src="assets/images/profile-pic.png" />

            </div>
            <div class="col text-center">
                <h2 class="mb-0">{{tenant?.frHead}}</h2>
                <p>{{tenant?.speciality}}</p>

            </div>
            <div class="col text-right">
                <img width="75px" src="assets/images/profile-pic.png" />

            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hr>
            </div>
            <div class="col-auto mr-auto">
                <h3 class="mb-0">
                    Mr, Jhon Doe, 24 year
                </h3>
            </div>
            <div class="col-auto">
                <h3 class="mb-0">Le, {{today | date}}</h3>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12" style="font-size: x-large;">
                <div>Détails...</div>
            </div>
        </div>
        <footer style="width:210mm;position: absolute;bottom: 0;">
            <div class="container">
                <div class="text-center">
                    <h5 class="mb-0">{{tenant?.address}}</h5>
                    <h5 class="mt-0">Tél: {{tenant?.fax}} - GSM:
                        {{tenant?.mobile}}</h5>
                </div>
            </div>
        </footer>
    </div>
</div>