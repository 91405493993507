import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {JwtToken} from './token.model';
import {Tokens} from '../../model/tokens';
import {environment} from '../../../../environments/environment';
import {UtilisateurModel} from '../../model/utilisateur.model';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import { DiscordService } from '../discord.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.notLoggedAPI + '/auth';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

  constructor(private http: HttpClient, private router: Router, private discordService: DiscordService) {}

  login(credential){
    return this.http.post<any>(this.apiUrl, credential, this.httpOptions ).subscribe((token) => {
      this.doLoginUser(token);
      this.router.navigate(['/']);
    }, error => {      
      Swal.fire({
        icon: 'info',
        text: `${error.error.response}`
      });
    });
  }
  getUsers(): Observable<UtilisateurModel[]> {
    return this.http.get<UtilisateurModel[]>(this.apiUrl);
  }

  getSpecialites(): Observable<string[]> {
    return this.http.get<string[]>("/assets/data/specialites.json");
  }

  registerUser(user: UtilisateurModel){
    return this.http.post<any>( this.apiUrl + '/signup', user).subscribe((token) => {
      this.sentDiscordNotification(user);
      this.doLoginUser(token);
      const packType = localStorage.getItem('subscription');
      if(packType){
        this.router.navigate(['/subscription/subscription-create']);
      } else {
        this.router.navigate(['/']);
      }
      Swal.fire({
        icon: 'info',
        title: 'Bienvenue chez Xtend-Medical',
        text: 'Nous sommes heureux que vous nous ayez choisis et nous voulons vous montrer notre appréciation en vous offrant 5 jours gratuits.'
      });
      
    }, error => {
      Swal.fire({
        icon: 'info',
        text: `${error.error.response}`
      });
    });
  }

  sentDiscordNotification(user: any){
    const body = {
      content: 'New sign up',
      embeds: [
        {
          author: {name: user.lastname + ' ' + user.firstname + ' / ' + user.phone},
          title: user.username,
          description: user.speciality
        }
      ]
    };
    this.discordService.doSendNotification(body).subscribe();
  }
  logout() {
    return this.doLogoutUser();
  }


  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {

    const body = new HttpParams().set('refresh_token' , this.getRefreshToken());
    return this.http.post<any>(this.apiUrl + '/login/refresh', body , this.httpOptions ).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.token);
    }));
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  doLoginUser(tokens: Tokens) {
    this.storeTokens(tokens);
  }


  private doLogoutUser() {
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.token);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
  }
  private getJwtTokens(): JwtToken {
    const decodedJwtJsonData = window.atob(this.getRefreshToken().split('.')[1]);
    const decodedJwtData = JSON.parse(decodedJwtJsonData) as JwtToken;

    decodedJwtData.exp = decodedJwtData.exp * 1000;
    decodedJwtData.iat = decodedJwtData.iat * 1000;
    return decodedJwtData;
  }
  public isAdmin(): boolean {
    return this.isLoggedIn() && this.roleMatch(['ADMIN']);
  }

  public isUser(): boolean {
    return this.isLoggedIn() && this.roleMatch(['USER']);
  }
  roleMatch(allowedRoles: string[]): boolean {
    let isMatch = false;
    const userRoles: string[] = this.getJwtTokens().roles[0].authority;
    allowedRoles.forEach(element => {
          if (userRoles.indexOf(element) > -1) {
            isMatch = true;

            return false;
          }
        }
    );
    return isMatch;

  }
  public getUserName(){
    return this.getJwtTokens().name;
  }

  public getUserId(){
    return this.getJwtTokens().tenant_id;
  }

  public getExpirationDate(){
    return this.getJwtTokens().expirationDate;
  }
}
