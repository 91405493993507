import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DiscordService {
    protected readonly apiUrl = 'https://discord.com/api/webhooks/979363075314548786/hXp_TcmQBR6TbZj6xVNOZZWma6zH1voJu7ZZdAOvZ8WPjjZJ3CPsLLlVv3ZZIh2NEPcI';
    constructor(private http: HttpClient) {}

    public doSendNotification(content): Observable<any> {
        return this.http.post<any>(this.apiUrl, content);
    }
}
