import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {Spinkit} from 'ng-http-loader';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public spinkit = Spinkit;
  title = 'multikart-backend';
  constructor(private router: Router) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        gtag('config', 'G-8KXKY1JZST',
            {
              page_path: event.urlAfterRedirects
            }
        );
      }
    })
  }
}
