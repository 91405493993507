import { Routes } from '@angular/router';
import { Authgard } from '../service/auth/auth.guard';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'users',
    canActivate: [Authgard],
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: 'Utilisateur'
    }
  },
  {
    path: 'settings',
    canActivate: [Authgard],
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: 'Settings'
    }
  },
  {
    path: 'subscription',
    loadChildren: () => import('../../components/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
    data: {
      breadcrumb: 'Abonnement'
    }
  },
  {
    path: 'patient',
    loadChildren: () => import('../../components/patient/patient.module').then(m => m.PatientModule),
    data: {
      breadcrumb: 'Patient'
    }
  },
  {
    path: 'consultation',
    loadChildren: () => import('../../components/consultation/consultation.module').then(m => m.ConsultationModule),
    data: {
      breadcrumb: 'Consultation'
    }
  },
  {
    path: 'calendar',
    loadChildren: () => import('../../components/appointment/appointment.module').then(m => m.AppointmentModule),
    data: {
      breadcrumb: 'calendar'
    }
  },
  {
    path: 'spents',
    loadChildren: () => import('../../components/spent/spent.module').then(m => m.SpentModule),
    data: {
      breadcrumb: 'Dépense'
    }
  },
  {
    path: 'survey',
    loadChildren: () => import('../../components/survey/survey.module').then(m => m.SurveyModule),
    data: {
      breadcrumb: 'Questionnaire'
    }
  },
];
