import { TenantModel } from "./../../../shared/model/models.model";
import { AuthService } from "./../../../shared/service/auth/auth.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SettingService } from "../../../shared/service/settings.service";
import { SettingModal, UserModel } from "../../../shared/model/models.model";
import { UserService } from "../../../shared/service/user.service";
import { interval } from "rxjs/internal/observable/interval";
import { map } from "rxjs/internal/operators/map";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from 'sweetalert2';

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  source: any;
  rdvTypeForm: FormGroup;
  passwordForm: FormGroup;
  submitted = false;
  rdvTypes: SettingModal[] = [];
  public activateEditBtn = false;
  token: string;
  public user: UserModel;
  expiryDate: any;
  today: any = new Date().getTime();
  private diff: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  tenant: TenantModel;
  enableTenantEditVariable: boolean;
  specialities: string[];
  constructor(
    private settingService: SettingService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private auth: AuthService,
    private modalService: NgbModal,
  ) {}
  ngOnInit() {
    this.userService.getProfileDetails().subscribe((e) => {
      this.user = e;
    });
    this.userService.getTenantDetails().subscribe((e) => {
      this.tenant = e;
      this.expiryDate = e.expirationDate;

      interval(30)
        .pipe(
          map((x) => {
            this.diff = this.expiryDate - Date.parse(new Date().toString());
          })
        )
        .subscribe((x) => {
          this.days = this.getDays(this.diff);
          this.hours = this.getHours(this.diff);
          this.minutes = this.getMinutes(this.diff);
          this.seconds = this.getSeconds(this.diff);
        });
    });
    this.auth.getSpecialites().subscribe(e => this.specialities = e);
    this.rdvTypeForm = this.formBuilder.group({
      id: [null],
      type: ["", [Validators.required]],
      price: ["", [Validators.required]],
      duration: ["", [Validators.required]],
      color: ["", [Validators.required]],
    });
    this.passwordForm = this.formBuilder.group({
      password: ["", [Validators.required]],
      newPassword: ["", [Validators.required]],
      confirmaion: ["", [Validators.required]]
    });
    this.settingService.getRdvType().subscribe((e) => (this.rdvTypes = e));
  
  }
  addModal(content) {
    this.submitted = false;
    // this.rdvTypeForm.reset();
    this.modalService.open(content, { size: 'sm', windowClass: 'modal-holder', centered: true });
  }

  submitRdvType() {
    if(!this.activateEditBtn){
      this.settingService
      .createRdvType(this.rdvTypeForm.value)
      .subscribe((response) => {
        this.rdvTypes.unshift(response);
        this.closeModal();
      });
    } else {
      this.updateRdvType();
    }
    
  }

  deleteRdvType(rdv: SettingModal) {
    Swal.fire({
      title: 'Confirmez-vous la suppression de ce rendez-vous ? <br> Cette exécution est irréversible.',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Oui`,
      denyButtonText: `Annuler`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.rdvTypes = this.rdvTypes.filter((e) => e.id !== rdv.id);
      }
    });
  }

  editRdvType(rdv: SettingModal, content) {
    this.rdvTypeForm.patchValue(rdv);
    this.activateEditBtn = true;
    this.modalService.open(content, { size: 'sm', windowClass: 'modal-holder', centered: true });
  }

  updateRdvType() {
    this.settingService
      .updateRdvType(this.rdvTypeForm.value)
      .subscribe((response) => {
        const index = this.rdvTypes.findIndex(
          (e) => e.id === this.rdvTypeForm.value.id
        );
        this.rdvTypes[index] = response;
        this.rdvTypeForm.reset();
        this.activateEditBtn = false;
        this.closeModal();
      });
  }

  closeModal(){
    this.modalService.dismissAll();
  }

  getDays(t) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }
  enableTenantEdit() {
    this.enableTenantEditVariable = !this.enableTenantEditVariable;
  }
  updateTenant() {
    this.userService.updateTenant(this.tenant).subscribe(() => this.enableTenantEdit())
  }

  updatePassword(){
    this.submitted= true;
    if(this.passwordForm.valid && this.passwordForm.value.newPassword === this.passwordForm.value.confirmaion){
      const user = {...this.user};
      user.password = this.passwordForm.value.password;
      user.newPassword = this.passwordForm.value.newPassword;
      this.userService.updateUserPassword(user).subscribe(() => {
        this.passwordForm.reset();
        this.submitted = false;
        Swal.fire({
          icon: "success",
        });
      }, ()=>  Swal.fire({
        icon: "error",
        text: "Mot de pass actuel incorrect"
      }));
    }
  }
}
