import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { SmartTableDatepickerComponent } from './components/smart-table-datepicker/smart-table-datepicker.component';
import { SmartTableDatepickerRenderComponent } from './components/smart-table-datepicker/smart-table-datepicker.component';
import {FormsModule} from '@angular/forms';
import {QuillModule} from 'ngx-quill';
import { PatientCardComponent } from './components/patient-card/patient-card.component';
import {MatIconModule} from '@angular/material/icon';
import {SafePipe} from './pipe/safe.pipe';
import { PrescriptionComponent } from './components/prescription/prescription.component';

@NgModule({
  declarations: [
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    SmartTableDatepickerComponent,
    SmartTableDatepickerRenderComponent,
    PatientCardComponent,
    SafePipe,
    PrescriptionComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        QuillModule,
        MatIconModule
    ],
  providers: [NavService, WINDOW_PROVIDERS],
    exports: [FeatherIconsComponent, ToggleFullscreenDirective,
       PatientCardComponent, SafePipe, PrescriptionComponent]
})
export class SharedModule { }
