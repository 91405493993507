import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from './windows.service';
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	isAdmin?: boolean;
}

@Injectable({providedIn: 'root'})
export class NavService {

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true;
		}
	}

	public screenWidth: any;
	public collapseSidebar = false;

	MENUITEMS: Menu[] = [
	// {
	// 	path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
	// },
    {
      path: '/calendar', title: 'Calendrier', icon: 'calendar', type: 'link', badgeType: 'primary', active: false
    },
    {
      title: 'Patients', icon: 'user-plus', path: '/patient/list-patient', type: 'link', active: false
      // children: [
      //   { path: '/patient/list-patient', title: 'patient List', type: 'link' },
      //   { path: '/patient/create-patient', title: 'Create patient', type: 'link' },
      // ]
    },
    {
      path: '/consultation', title: 'Consultations', icon: 'file', type: 'link', badgeType: 'primary', active: false, isAdmin: true
    },
    {
      path: '/consultation/waiting', title: 'Salle d\'attente', icon: 'watch', type: 'link', badgeType: 'primary', active: false
    },
	{
		title: 'Caisse', icon: 'dollar-sign', type: 'sub', active: false, children: [
			{ path: '/spents/list-spent', title: 'Dépense', type: 'link' },
			{ path: '/spents/list-caisse', title: 'Révenue', type: 'link' },
		]
	},
	{
		title: 'Utilisateur', icon: 'users', type: 'sub', active: false,isAdmin: true, children: [
			{ path: '/users/list-user', title: 'Liste des Utilisateur', type: 'link' },
			{ path: '/users/create-user', title: 'Ajouter un utilisateur', type: 'link' },
		]
	},
	{
		path: '/survey', title: 'Questionnaire', icon: 'help-circle', type: 'link', badgeType: 'primary', active: false
	},
	{
		title: 'Abonnement', icon: 'file-text', type: 'sub', active: false,isAdmin: true, children: [
			{ path: '/subscription/subscription-list', title: 'Historique', type: 'link' },
			{ path: '/subscription/subscription-create', title: 'Renouveler votre abonnement', type: 'link' },
		]
	},
	{
		path: '/settings/profile', title: 'Paramètres', icon: 'settings',isAdmin: true, type: 'link', badgeType: 'primary', active: false
	},
	{
		title: 'Déconnecter', path: '/auth/login', icon: 'log-out', type: 'link', active: false
	}
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}


}
