import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UserService} from '../../../shared/service/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public accountForm: FormGroup;
  public permissionForm: FormGroup;
  public submitted = false;
  constructor(private formBuilder: FormBuilder, private userService: UserService) {
    this.createAccountForm();
    this.createPermissionForm();
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      username: ['',  [Validators.required, Validators.email]],
      role: [null, [Validators.required]],
      password: ['', [Validators.required]],
      confirmPwd: ['', [Validators.required]]
    })
  }
  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  ngOnInit() {
  }

  addUser() {
    this.submitted = true;
    if (this.accountForm.valid){
      if (this.accountForm.value.password === this.accountForm.value.confirmPwd) {
        this.userService.addUser(this.accountForm.value).subscribe((response) => {
          Swal.fire(
              'Succès!',
              'Utilisateur ajouter!',
              'success'
          );
          this.accountForm.reset();
        }, (error: any) => Swal.fire(
            'error'
        ));
      } else {
        Swal.fire(
            'Mot de passe incorrect!',
            'Veuillez vérifier!',
            'info'
        );
      }
    } else {
      Swal.fire(
        'Formulaire manquant!',
        'Veuillez vérifier les champs obligatoires!',
        'info'
      );
    }
  }
}
