<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <div class="card bg-primary" style="background-image: unset;">
                        <div class="single-item">
                            <owl-carousel-o [options]="owlcarouselOptions">
                                <ng-container *ngFor="let data of owlcarousel">
                                    <ng-template carouselSlide class="item">
                                        <div>
                                            <h3>{{data.title}}</h3>
                                            <p>{{data.desc}}</p>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab #login>
                                    <ng-template ngbTabTitle><i class="icon-user mr-2"></i>Se connecter</ng-template>
                                    <ng-template ngbTabContent>

                                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                            <div class="form-group">
                                                <input formControlName="username" type="email" name="email"
                                                    class="form-control" placeholder="e-mail"
                                                    [ngClass]="{ 'is-invalid': submitted && loginForm.controls.username.errors }">
                                            </div>
                                            <div class="form-group">
                                                <input formControlName="password" type="password" name="password"
                                                    class="form-control" placeholder="mot de passe"
                                                    [ngClass]="{ 'is-invalid': submitted && loginForm.controls.password.errors }">
                                            </div>
                                            <div class="form-terms">
                                                <div class="custom-control custom-checkbox mr-sm-2">
                                                    <input type="checkbox" class="custom-control-input"
                                                        id="customControlAutosizing">
                                                    <label class="custom-control-label" for="customControlAutosizing">me
                                                        souviens</label>
                                                    <a href="javascript:void(0)" class="btn btn-default forgot-pass">Mot
                                                        de passe oublié</a>
                                                </div>
                                            </div>
                                            <div class="form-button">
                                                <button class="btn btn-primary" type="submit">Se connecter</button>
                                            </div>
                                            <!--      <div class="form-footer">
                                                    <span>Or Login up with social platforms</span>
                                                    <ul class="social">
                                                        <li><a class="icon-facebook" href="javascript:void(0)"></a></li>
                                                        <li><a class="icon-twitter" href="javascript:void(0)"></a></li>
                                                        <li><a class="icon-instagram" href="javascript:void(0)"></a>
                                                        </li>
                                                        <li><a class="icon-pinterest" href="javascript:void(0)"></a>
                                                        </li>
                                                    </ul>
                                                </div>-->
                                        </form>
                                        <hr>
                                        <div class="mx-auto">
                                            <p class="mb-0 mt-3"><small class="text-dark me-2">Vous n'avez pas de compte
                                                    ?</small> <a routerLink="/auth/signup"
                                                    class="btn btn-secondary ml-2">S'inscrire gratuitement</a></p>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>