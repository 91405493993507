import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { UsersModule } from './components/users/users.module';
import { SettingModule } from './components/setting/setting.module';
import { AuthModule } from './components/auth/auth.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './shared/service/auth/TokenInterceptor';
import {MatDialogModule} from '@angular/material/dialog';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {NgHttpLoaderModule} from 'ng-http-loader';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { CKEditorModule } from 'ngx-ckeditor';

registerLocaleData(localeFr);
@NgModule({
  declarations: [
    AppComponent,
  ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        AppRoutingModule,
        DashboardModule,
        SettingModule,
        AuthModule,
        SharedModule,
        UsersModule,
        HttpClientModule,
        MatDialogModule,
        CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
        NgHttpLoaderModule.forRoot(),
        CKEditorModule
    ],
  providers: [    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      { provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
