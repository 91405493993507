import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import Swal from 'sweetalert2';

@Injectable({providedIn: 'root'})
export class LoginActivate implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('JWT_TOKEN')) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url
      }});
    return false;
  }
}

@Injectable({providedIn: 'root'})
export class Authgard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('JWT_TOKEN') && this.authService.isAdmin()) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/']);
    Swal.fire({
      icon: "error",
      text: "Vous n'avez pas accès à cette page"
    });
    return false;
  }
}
