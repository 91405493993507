<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <div class="card bg-primary" style="background-image: unset;">

                        <div class="single-item">
                            <owl-carousel-o [options]="owlcarouselOptions">
                                <ng-container *ngFor="let data of owlcarousel">
                                    <ng-template carouselSlide class="item">
                                        <div>
                                            <h3>{{data.title}}</h3>
                                            <p>{{data.desc}}</p>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab #signup2>
                                    <ng-template ngbTabTitle><i class="icon-unlock mr-2"></i> S'inscrire
                                        <ng-template ngbTabContent>
                                            <form [formGroup]="registerForm" (ngSubmit)="signup()">
                                                <div class="form-group">
                                                    <input formControlName="name" type="text" class="form-control"
                                                        placeholder="Nom de votre cabinet (DR.Jhon)"
                                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.name.errors }">
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <input formControlName="lastname" type="text"
                                                                class="form-control" placeholder="Nom"
                                                                [ngClass]="{ 'is-invalid': submitted && registerForm.controls.lastname.errors }">
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <input formControlName="firstname" type="text"
                                                                class="form-control" placeholder="Prénom"
                                                                [ngClass]="{ 'is-invalid': submitted && registerForm.controls.firstname.errors }">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <ng-select virtualScroll="true" formControlName="speciality"
                                                        [placeholder]="'Indiquer votre Spécialité'" [multiple]="false"
                                                        [ngClass]="{'is-invalid': (submitted && registerForm.controls.speciality.errors) || (registerForm.controls.speciality.touched && registerForm.controls.speciality.errors)}">
                                                        <ng-option *ngFor="let specialitie of specialities"
                                                            [value]="specialitie">{{specialitie}}</ng-option>
                                                    </ng-select>
                                                    <span class="badge text-danger"
                                                    *ngIf="(submitted && registerForm.controls.speciality.errors) || (registerForm.controls.speciality.touched && registerForm.controls.speciality.errors)">Champs requis*</span>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <ngx-intl-tel-input 
                                                            [cssClass]="{ 'form-control' : 1, 'is-invalid': submitted && registerForm.controls.phone.errors }"
                                                                [preferredCountries]="preferredCountries"
                                                                [enableAutoCountrySelect]="true"
                                                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                                [selectFirstCountry]="true"
                                                                [selectedCountryISO]="CountryISO.Tunisia"
                                                                [phoneValidation]="true"
                                                                [separateDialCode]="separateDialCode"
                                                                [numberFormat]="PhoneNumberFormat.National"
                                                                formControlName="phone" name="phone">
                                                            </ngx-intl-tel-input>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <input formControlName="username" type="email"
                                                                class="form-control" placeholder="E-mail"
                                                                [ngClass]="{ 'is-invalid': submitted && registerForm.controls.username.errors }">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input formControlName="password" type="password"
                                                        class="form-control" placeholder="Mot de passe"
                                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.password.errors }">
                                                </div>
                                                <div class="form-terms">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customControlAutosizing1">
                                                        <label class="custom-control-label"
                                                            for="customControlAutosizing1"><span>J'accepte toutes les
                                                                déclarations dans
                                                                <a href="javascript:void(0)" > Terms &
                                                                    Conditions</a>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-button">
                                                    <button class="btn btn-primary" type="submit">S'inscrire gratuitement</button>
                                                </div>
                                            </form>
                                            <hr>
                                            <div class="mx-auto">
                                                <p class="mb-0 mt-3"><small class="text-dark me-2">Vous avez déjà un compte ?</small> <a
                                                    routerLink="/auth/login" class="btn btn-secondary ml-2">S'identifier</a></p>
                                            </div>
                                        </ng-template>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>