import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/service/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private auth: AuthService) {
    this.createLoginForm();
  }

  owlcarousel = [
    {
      title: "Bienvenue chez Xtend-Medical",
      desc: "Une simple inscription vous permettra d’avoir votre espace cloud privé et sécurisé dédié à votre cabinet.",
    },
    {
      title: "Bienvenue chez Xtend-Medical",
      desc: "Vous bénéficierez de 5 jours d’utilisation gratuite, après lequel vous devrez renouveler votre abonnement",
    },
    {
      title: "Bienvenue chez Xtend-Medical",
      desc: "Le premier ERP-Medical dédie au médecin pour digitaliser ces travails",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };
  submitted = false;

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    })
  }

  ngOnInit() {
    this.auth.logout();   
  }

  onSubmit() {
    this.submitNo();    
    if (this.loginForm.valid){
      this.auth.login(this.loginForm.value);
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Formulaire manquant',
        text: 'Verifiez le formulaire'
      });
    }
  }

  private submitNo() {
    this.submitted = !this.submitted;
  }
}
