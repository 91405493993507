<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 footer-copyright">
            <p class="mb-0">Copyright {{date | date: 'yyyy'}} <a href="https://xtendplex.com" target="_blank">© XtendPlex</a> All rights reserved.</p>
        </div>
        <div class="col-md-6">
            <p class="pull-right mb-0"><i class="fa fa-heart"></i> 
            </p>
        </div>
    </div>
</div>