import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../shared/service/user.service';
import Swal from 'sweetalert2';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {
  public user_list = [];
  public source = new LocalDataSource()

  constructor(private userService: UserService) {
    this.userService.getUsers().subscribe((users) => {
      this.user_list = users
      this.source.load(this.user_list);});
  }

  public settings = {
    noDataMessage: 'Aucune donnée disponible',
    actions: {
      add: false,
      delete: true,
      position: 'right', // left|right
    },
    columns: {
      firstname: {
        title: 'Prénom',
      },
      lastname: {
        title: 'Nom'
      },
      email: {
        title: 'E-mail'
      },
      lastLogin: {
        title: 'Dérnier visite',
        filter: false,
        valuePrepareFunction: (value) => {
          if (value){
            const today = new Date(value);
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            const time = today.getHours() + ':' + today.getMinutes();
            return date + ' à ' + time;
          }
        }
      },
      roles: {
        title: 'Rôle'
      },
    },
    mode: 'external'
  };

  ngOnInit() {
  }

  deleteUser(row: any) {
    Swal.fire({
      title: 'Confirmez-vous la suppression de cet utilisateur ? <br> Cette exécution est irréversible.',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Oui`,
      denyButtonText: `Annuler`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteUser(row.data.userId).subscribe(() =>  {
          const index = this.user_list.indexOf(row.data);
          this.user_list.splice(index, 1);
          this.source.load(this.user_list);
        });
      }
    });
  }

}

