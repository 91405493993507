import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SettingRoutingModule } from './setting-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from '../../shared/shared.module';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
  declarations: [ProfileComponent],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        SettingRoutingModule,
        SharedModule,
        Ng2SmartTableModule,
        NgSelectModule,
        NgxPrintModule
    ]
})
export class SettingModule { }
